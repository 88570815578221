const isDevelopment: boolean =
  process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test";

export const TOURNAMENT_NAME = isDevelopment
  ? "gdex-cup"
  : "gdex-cup";

export const API_PATH = isDevelopment
  ? "http://localhost:8444"
  : "https://tournament-api.cryptoknights.games";

export const AUTH_PATH = isDevelopment
  ? "http://localhost:8222"
  : "https://auth.cryptoknights.games";
