import {
  Modal,
  notification,
  Popover,
  Form,
  Input,
  Button,
  Switch,
  Select,
} from "antd";
import "antd/dist/antd.min.css";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import "./App.css";
import { API_PATH } from "./env";

const { Option } = Select;

interface State {
  tournamentName: string;
  tournamentSlug: string;
  tournaments: string[];
  selectedTournament: string;
  rounds: string[]; // all round
  selectedRound: string; // selected round etc . final
  groups: number[]; //all group
  selectedGroup: number;
  observerDelay: number;
  isObserve: Boolean;
  displayMatches: Match[]; // all matches
  pickWinner?: Winner;
  visibleObserverInsert: boolean;
  observerInsertStatus: string;
  observerList: Observer[];
  availibleList: Observer[];
  reserveObserver: Observer[];
  selectedObserver: Observer[];
}
interface Tournament {
  slug: string;
  name: string;
  rounds: string[];
  last_updated_at: string;
}
interface Observer {
  account_id: string;
  name: string;
}
interface Match {
  id: string;
  player_a: Player;
  player_b: Player;
  winner_name?: string;
  rooms: Room[];
  observer: Observer;
}
interface Room {
  id: string;
  player_a_status: string;
  player_b_status: string;
  status: string;
  status_details?: string;
  winner_name?: string;
}
interface Player {
  is_online: boolean;
  participant_number: number;
  participant_status: string;
  // invite_received: boolean;
  id: string;
  name: string;
  knight_id: string;
  knight_name: string;
}
interface Winner {
  winner_id: string;
  winner_name: string;
  match_id: string;
}
interface FightAllBody {
  matches: {
    id: number;
    player_a_id: string;
    player_b_id: string;
    observer_id: string;
    observer_delay: number;
  }[];
}
type Action =
  | { type: "selected_round_changed"; selectedRound: string }
  | { type: "selected_group_changed"; selected: number }
  | { type: "selected_tournament_changed"; selectedTournament: string }
  | { type: "rounds_updated"; rounds: string[] }
  | {
      type: "tournament_config_updated";
      default: {
        tournaments: string[];
        tournamentSlug: string;
        selectedRound: string;
        rounds: string[];
      };
    }
  | {
      type: "tournaments_updated";
      tournaments: [{ tournament: string; rounds: string[] }];
    }
  | { type: "groups_updated"; groups: number[] }
  | { type: "matches_updated"; displayMatches: Match[] }
  | { type: "tournament_name_changed"; tournamentName: string }
  | { type: "winner_selected"; winner: Winner }
  | { type: "unselected_winner" }
  | { type: "update_observer"; observe: boolean }
  | { type: "delay_updated"; delay: number }
  | { type: "open_observer_modal" }
  | { type: "close_observer_modal" }
  | { type: "observer_insert_status_update"; status: string }
  | { type: "observer_updated"; body: Observer[] }
  | { type: "set_observer"; updatedMatches: Match[] }
  | { type: "observer_reserve"; body: Observer }
  | { type: "clear_observer_clicked"; index: number }
  | { type: "tournament_slug_changed"; tournamentSlug: string };

function reducer(state: State, action: Action) {
  switch (action.type) {
    case "selected_group_changed":
      return { ...state, selectedGroup: action.selected };
    case "selected_round_changed":
      return { ...state, selectedRound: action.selectedRound };
    case "set_observer":
      return { ...state, displayMatches: action.updatedMatches };
    case "clear_observer_clicked":
      const observer = state.displayMatches[action.index].observer;
      let temp = state.displayMatches;
      // @ts-ignore
      temp[action.index].observer = null;

      //add to abailible
      state.availibleList.push(observer);
      return {
        ...state,
        displayMatches: temp,
      };
    case "observer_reserve":
      const index = state.availibleList.findIndex(
        (l) => l.account_id === action.body.account_id
      );
      if (index !== -1) {
        state.availibleList.splice(index, 1);
      }
      state.reserveObserver.push(action.body);
      return {
        ...state,
        reserveObserver: state.reserveObserver,
        availibleList: state.availibleList,
      };
    case "rounds_updated":
      return { ...state, rounds: action.rounds };
    case "observer_updated":
      return {
        ...state,
        observerList: action.body,
        availibleList: action.body,
      };
    case "selected_tournament_changed":
      return { ...state, selectedTournament: action.selectedTournament };
    case "tournament_config_updated":
      return {
        ...state,
        tournaments: action.default.tournaments,
        rounds: action.default.rounds,
        selectedRound: action.default.selectedRound,
        selectedTournament: action.default.tournamentSlug,
      };
    case "tournaments_updated":
      return {
        ...state,
        tournaments: action.tournaments.map((t) => t.tournament),
      };
    case "tournament_name_changed":
      return { ...state, tournamentName: action.tournamentName };
    case "tournament_slug_changed":
      return { ...state, tournamentSlug: action.tournamentSlug };
    case "observer_insert_status_update":
      return { ...state, observerInsertStatus: action.status };
    case "groups_updated":
      return { ...state, groups: action.groups };
    case "open_observer_modal":
      return { ...state, visibleObserverInsert: true };
    case "close_observer_modal":
      return { ...state, visibleObserverInsert: false };
    case "matches_updated":
      return { ...state, displayMatches: action.displayMatches };
    case "winner_selected":
      return { ...state, pickWinner: action.winner };
    case "unselected_winner":
      return { ...state, pickWinner: undefined };
    case "update_observer":
      return { ...state, isObserve: action.observe };
    case "delay_updated":
      return { ...state, observerDelay: action.delay };
    default:
      throw new Error("error");
  }
}
type FP = {
  visible: boolean;
  onCancel: () => void;
  onCreate: (value: string) => void;
  detail: string;
};
const CollectionCreateForm = (props: FP) => {
  const [form] = Form.useForm();
  return (
    <Modal
      visible={props.visible}
      title="Add a new observer"
      okText="Create"
      cancelText="Cancel"
      onCancel={props.onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            console.log("x", values);
            // form.resetFields();
            props.onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: "public",
        }}
      >
        <Form.Item
          name="observer_id"
          label="Observer id"
          rules={[
            {
              required: true,
              message: "Please input the observer id!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="observer_name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Please input name",
            },
          ]}
        >
          <Input type="textarea" />
        </Form.Item>

        <Form.Item
          // name="modifier"
          className="collection-create-form_last-form-item"
        ></Form.Item>
        <div className="text-red-500">{props.detail}</div>
      </Form>
    </Modal>
  );
};
function Tournament() {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [state, dispatch] = useReducer(reducer, {
    tournamentName: "",
    tournamentSlug: "",
    rounds: [""],
    tournaments: [],
    selectedRound: "-",
    selectedTournament: "-",
    groups: [0],
    selectedGroup: 0,
    observerDelay: 5,
    displayMatches: [],
    isObserve: false,
    pickWinner: undefined,
    visibleObserverInsert: false,
    observerInsertStatus: "",
    observerList: [],
    availibleList: [],
    reserveObserver: [],
    selectedObserver: [],
  });
  const fightClicked = useCallback(
    async (
      a: Player,
      b: Player,
      match_id: string,
      index: number,
      observer_id: string
    ) => {
      console.log("oid", observer_id);
      // const input = document.getElementById(`moderator_${index}`);
      // // @ts-ignore
      // const observe_id = input?.value;
      const body: FightAllBody = {
        matches: [
          {
            id: parseInt(match_id),
            player_a_id: a.id,
            player_b_id: b.id,
            observer_delay: state.observerDelay,
            observer_id: observer_id,
          },
        ],
      };
      const jwt = localStorage.getItem("AuthJWT");
      const response = await fetch(`${API_PATH}/match/fight`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: "Bearer " + jwt,
        },
        body: JSON.stringify(body),
      });
      const resp = await response.json();
      switch (response.status) {
        case 200:
          notification["success"]({
            message: "",
            description: "Invited",
          });
          break;
        case 400:
          notification["error"]({
            message: "",
            description: "Error",
          });
          break;
        default:
          notification["error"]({
            message: "",
            description: "Something went wrong",
          });
          break;
      }
      fetchMatchInGroup()
        .then((r) => {
          dispatch({ type: "matches_updated", displayMatches: r });
        })
        .catch((err) => {
          console.error("error while fetch match", err);
        });
    },
    [[state.selectedGroup, state.selectedRound, state.selectedTournament]] // eslint-disable-line react-hooks/exhaustive-deps
  );
  const fetchTournaments = async (): Promise<{
    body: [{ tournament: string; rounds: string[] }];
  }> => {
    const jwt = localStorage.getItem("AuthJWT");
    const resp = await fetch(`${API_PATH}/tournament`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + jwt,
      },
    });
    const temp = await resp.json();
    console.log("temp", temp);
    return {
      body: temp.tournaments.map((t: Tournament) => ({
        tournament: t.slug,
        rounds: t.rounds,
      })),
    };
  };
  const fetchRoundByTournament = async (): Promise<{
    slug: string;
    name: string;
    rounds: string[];
  }> => {
    const jwt = localStorage.getItem("AuthJWT");
    const resp = await fetch(
      `${API_PATH}/tournament/${state.selectedTournament}`,
      {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: "Bearer " + jwt,
        },
      }
    );
    return await resp.json();
  };
  const fetchObservers = async (): Promise<{
    observers: { account_id: string; name: string }[];
  }> => {
    const jwt = localStorage.getItem("AuthJWT");
    const resp = await fetch(
      `${API_PATH}/tournament/${state.selectedTournament}/observers`,
      {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: "Bearer " + jwt,
        },
      }
    );
    const json = await resp.json();
    console.log("observer ", json);
    return json;
  };
  const handleObserveClicked = async (a: boolean) => {
    dispatch({
      type: "update_observer",
      observe: a,
    });
  };
  const fetchGroupsInRound = async (): Promise<{
    groups: number[];
    round: { slug: string; name: string };
    tournament: { slug: string; name: string };
  }> => {
    const jwt = localStorage.getItem("AuthJWT");

    const resp = await fetch(`${API_PATH}/round/${state.selectedRound}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + jwt,
      },
    });
    return await resp.json();
  };
  const fetchMatchInGroup = async () => {
    console.log("fetch");
    const jwt = localStorage.getItem("AuthJWT");
    console.log("select", state.selectedRound);
    const resp = await fetch(
      `${API_PATH}/round/${state.selectedRound}/group/${state.selectedGroup}`,
      {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: "Bearer " + jwt,
        },
      }
    );
    const data = await resp.json();
    const constructedData: Match[] = data.matches.map(
      (d: Match, index: number) => {
        return {
          id: d.id ? d.id : "",
          player_a: d.player_a,
          player_b: d.player_b,
          winner_name: d.winner_name,
          rooms: d.rooms,
          observer: state.displayMatches[index]?.observer
            ? state.displayMatches[index]?.observer
            : null,
        };
      }
    );
    console.log("success fetched", constructedData);
    return constructedData;
  };
  useEffect(() => {
    fetchTournaments().then((t) => {
      dispatch({
        type: "tournaments_updated",
        tournaments: t.body,
      });
    });
    fetchObservers().then((o) => {
      dispatch({
        type: "observer_updated",
        body: o.observers,
      });
    });
    fetchRoundByTournament()
      .then((t) => {
        dispatch({ type: "rounds_updated", rounds: t.rounds });
      })
      .catch((err) => {
        console.error("fetch round or update round Error", err);
      });
    fetchGroupsInRound()
      .then((r) => {
        dispatch({ type: "groups_updated", groups: r.groups });
      })
      .catch((err) => {
        console.error("fetch group in round error ", err);
      });
    // const intervalId = setInterval(() => {
    fetchMatchInGroup()
      .then((r) => {
        dispatch({ type: "matches_updated", displayMatches: r });
      })
      .catch((err) => {
        console.error("error while fetch match", err);
      });
    // }, 20000);

    // return () => clearInterval(intervalId); //This is important
  }, [state.selectedGroup, state.selectedRound, state.selectedTournament]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectedRound = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch({
      type: "selected_round_changed",
      selectedRound: e.target.value,
    });
  };
  const handleSelectedTournament = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    console.log("selected", e.target.value);
    dispatch({
      type: "selected_tournament_changed",
      selectedTournament: e.target.value,
    });
  };
  const handleNewObserverClicked = () => {
    dispatch({ type: "open_observer_modal" });
  };
  const closeObserver = () => {
    dispatch({ type: "close_observer_modal" });
  };
  const handleObserverInsert = async (values: any) => {
    const jwt = localStorage.getItem("AuthJWT");
    const response = await fetch(
      `${API_PATH}/tournament/${state.selectedTournament}/observer/${values.observer_id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: "Bearer " + jwt,
        },
        body: JSON.stringify({
          name: values.observer_name,
          account_id: values.observer_id,
        }),
      }
    );
    console.log("resp", response);
    if (response.status === 200) {
      notification["success"]({
        message: "",
        description: "Insert success",
      });
      dispatch({ type: "close_observer_modal" });
    } else {
      dispatch({ type: "observer_insert_status_update", status: "failed" });
    }
  };
  const handleFightAll = async (e: React.MouseEvent<HTMLElement>) => {
    const enable = state.displayMatches.filter(
      (m) =>
        m.player_a?.is_online && m.player_b?.is_online && m.rooms.length === 0
    );
    const must_fight = enable.filter((e) =>
      e.rooms.length === 0
        ? true
        : e.rooms[e.rooms.length - 1].status === "draw"
    );
    const body: FightAllBody = {
      matches: must_fight.map((m, index) => {
        // const indexT = state.displayMatches.findIndex((d) => d.id === m.id);
        // const input = document.getElementById(`moderator_${indexT + 1}`);
        // // @ts-ignore
        // const observe_id = input?.value;
        return {
          id: parseInt(m.id),
          player_a_id: m.player_a.id,
          player_b_id: m.player_b.id,
          observer_delay: state.observerDelay,
          observer_id: m.observer?.account_id,
        };
      }),
    };
    console.log("bd", body);
    const jwt = localStorage.getItem("AuthJWT");

    const response = await fetch(`${API_PATH}/match/fight`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + jwt,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      notification["success"]({
        message: "",
        description: `Invited ${must_fight.length} match`,
      });
      fetchMatchInGroup()
        .then((r) => {
          dispatch({ type: "matches_updated", displayMatches: r });
        })
        .catch((err) => {
          console.error("error while fetch match", err);
        });
    } else {
      notification["error"]({
        message: "",
        description: `Something Went Wrong.`,
      });
    }
  };
  const handleCancel = () => {
    dispatch({
      type: "unselected_winner",
    });
  };
  const handleGroupSelected = (n: number) => {
    dispatch({ type: "selected_group_changed", selected: n });
  };
  const handleObserverClearClicked = (index: number) => {
    console.log("index", index);
    dispatch({ type: "clear_observer_clicked", index: index });
  };
  const handleObserverChanged = (
    observer_index: number,
    match_index: number
  ) => {
    const observer_updated = {
      ...state.displayMatches[match_index].observer,
      account_id: state.availibleList[observer_index].account_id,
      name: state.availibleList[observer_index].name,
    };
    const matches_updated = {
      ...state.displayMatches[match_index],
      observer: observer_updated,
    };
    let temp = state.displayMatches;
    temp[match_index] = matches_updated;
    dispatch({ type: "set_observer", updatedMatches: temp });
    dispatch({ type: "observer_reserve", body: observer_updated });
  };
  const handleWinnerConfirm = async () => {
    setConfirmLoading(true);
    await setTimeout(async () => {
      dispatch({
        type: "unselected_winner",
      });
      setConfirmLoading(false);
      const jwt = localStorage.getItem("AuthJWT");

      const response = await fetch(
        `${API_PATH}/match/${state.pickWinner?.match_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + jwt,
          },
          body: JSON.stringify({
            winner_id: state.pickWinner?.winner_id,
          }),
        }
      );
      if (response.status === 200) {
        notification["success"]({
          message: "",
          description: `Success`,
        });
      } else {
        notification["error"]({
          message: "",
          description: `Something Went Wrong.`,
        });
      }
    }, 2000);
  };
  const handleReloadClicked = () => {
    fetchMatchInGroup().then((r) => {
      dispatch({ type: "matches_updated", displayMatches: r });
    });
  };
  // const handleOnInput = (a: React.KeyboardEvent<HTMLInputElement>) => {
  //   dispatch({ type: "delay_updated", delay: parseInt(a.currentTarget.value) });
  // };
  const handleSelectedWinner = (pickWinner: Winner) => {
    dispatch({ type: "winner_selected", winner: pickWinner });
  };
  const handleObserverInput = (
    index: number,
    a: React.KeyboardEvent<HTMLInputElement>
  ) => {
    console.log("index >>", index);
    console.log("value ->> ", a.currentTarget.value);
  };
  return (
    <div className="relative min-h-screen bg-slate-900 text-white flex flex-col items-center justify-center">
      <div className="w-4/5 mx-auto mt-8 p-4 sm:p-14 bg-white rounded-md text-slate-900 overflow-x-auto">
        <div className="mb-4">
          <p className="text-xl font-bold">
            <span>Tournament </span>
          </p>
        </div>
        <hr className="py-2"></hr>
        <div>
          <span className="my-auto"> Tournament : </span>
          <span>
            <select
              value={state.selectedTournament}
              name="tournament"
              className="rounded-md px-2 py-2 bg-gray-100"
              id="tournament-selected"
              onChange={handleSelectedTournament}
            >
              <option value={"-"} key=""></option>
              {state.tournaments.map((t) => (
                <option value={t} key={t}>
                  {t}
                </option>
              ))}
            </select>
          </span>
        </div>
        <div className="flex flex-col sm:flex-row mt-4 gap-y-4 sm:gap-y-0">
          <span className="my-auto"> Round : </span>
          <span>
            <select
              value={state.selectedRound}
              name="round"
              className="rounded-md px-2 py-2 bg-gray-100"
              id="round"
              onChange={handleSelectedRound}
            >
              <option value={"-"} key=""></option>
              {state.rounds?.map((l) => (
                <option value={l} key={l}>
                  {l}
                </option>
              ))}
            </select>
          </span>
          <button
            onClick={handleFightAll}
            className="fightBtn flex  justify-center py-2 sm:py-0 sm:justify-end items-center bg-red-400 px-8 sm:ml-auto rounded-lg text-white"
          >
            Fight All
          </button>
          <div className="flex gap-x-4 text-gray-500 items-center justify-end ml-auto bg-slate-50 px-8 rounded-md">
            {state.groups?.map((g) => (
              <span
                className={`${
                  state.selectedGroup === g ? "text-black" : "text-gray-400"
                } cursor-pointer`}
                key={g}
                onClick={() => handleGroupSelected(g)}
              >
                {g + 1}
              </span>
            ))}
          </div>
        </div>

        <div className="flex flex-row mt-4 mb-4">
          {/* <div className="text-blue-500 underline">
            <button onClick={handleReloadClicked}>Reload Result</button>
          </div> */}
          <div className="">
            <Button type="primary" onClick={handleNewObserverClicked}>
              New Observer
            </Button>
            <CollectionCreateForm
              // props={
              visible={state.visibleObserverInsert}
              onCreate={handleObserverInsert}
              onCancel={() => {
                closeObserver();
              }}
              detail={state.observerInsertStatus}
            />
          </div>
          <div className="ml-auto flex flex-row gap-x-2 text-slate-900 rounded-lg px-2 py-2">
            <span>Observe : </span>
            <Switch
              checkedChildren="On"
              unCheckedChildren="Off"
              onChange={handleObserveClicked}
            />
            {/* <span className="flex flex-row gap-x-2">
              <span
                onClick={() => handleObserveClicked(true)}
                className={
                  `${state.isObserve ? "font-bold" : "text-gray-400"}` +
                  " cursor-pointer"
                }
              >
                On
              </span>
              <span
                onClick={() => handleObserveClicked(false)}
                className={
                  `${!state.isObserve ? "font-bold" : "text-gray-400"}` +
                  " cursor-pointer"
                }
              >
                Off
              </span>
            </span> */}
          </div>
          {/* <div
            className={
              `${state.isObserve ? "" : "opacity-20"}` +
              ` my-auto px-2 flex flex-row gap-x-2`
            }
          >
            <span>Delay</span>
            <input
              value={state.observerDelay}
              onInput={handleOnInput}
              disabled={!state.isObserve}
              type="number"
              className="border border-gray-300 w-8 rounded-md px-1"
            ></input>
            <span>Sec</span>
          </div> */}
        </div>
        <Modal
          visible={state.pickWinner !== undefined}
          onOk={handleWinnerConfirm}
          onCancel={handleCancel}
          confirmLoading={confirmLoading}
          okText="Confirm"
        >
          <p>
            <span>
              Please confirm that you want to make{" "}
              <span className="font-bold text-lg text-red-500 underline px-1">
                {state.pickWinner?.winner_name}
              </span>
              as the winner of this match.
            </span>
          </p>
          <span className="text-xs text-gray-300">
            id :{state.pickWinner?.winner_id}
          </span>
        </Modal>
        <table
          id="table"
          className="w-full text-sm text-left text-gray-500 dark:text-gray-400 overflow-x-auto"
        >
          <thead className="w-auto text-xs py-2 text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr className="h-8">
              <th className="w-1/12">no</th>
              <th className="w-1/12">invite</th>
              <th className="w-2/12">Player-1</th>
              <th className="w-1/12">invite</th>
              <th className="w-2/12">Player-2</th>
              <th className="w-2/12">Winner</th>
              <th className="w-1/12">Status</th>
              <th className=""></th>
              <th className=""></th>
              <th className=""></th>
            </tr>
          </thead>
          <tbody className="w-auto">
            {state.displayMatches.map((t: Match, index: number) => (
              <tr
                className="w-full border-b-2 border-slate-100 hover:bg-slate-100"
                key={t.id}
              >
                <th title={t.id}>
                  <Popover
                    trigger="click"
                    content={
                      <div>
                        <p>player_1.id : {t.player_a?.id}</p>
                        <p>player_2.id : {t.player_b?.id}</p>
                      </div>
                    }
                  >
                    {index + 1}
                  </Popover>
                </th>
                <th className="h-full">
                  {t.rooms?.map((r, index) => (
                    <p className="m-0 p-0" key={index}>
                      {r.player_a_status}
                    </p>
                  ))}
                </th>
                <td className="w-24 overflow-x-auto">
                  {t.player_a !== null && (
                    <span
                      onClick={() =>
                        handleSelectedWinner({
                          winner_id: t.player_a.id,
                          winner_name: t.player_a.name,
                          match_id: t.id,
                        })
                      }
                      title={t.player_a.id}
                    >
                      <div
                        className={`${
                          t.player_a.is_online ? "bg-green-500" : "bg-red-800"
                        } + rounded-full w-3 h-3 my-auto mx-1`}
                      ></div>
                      <p className="cursor-pointer my-auto">
                        {t.player_a.name}(No.{t.player_a.participant_number})
                      </p>
                    </span>
                  )}
                </td>
                <th className="h-full">
                  {t.rooms?.map((r, index) => (
                    <p className="m-0 p-0" key={index}>
                      {r.player_b_status}
                    </p>
                  ))}
                </th>
                <th>
                  {t.player_b !== null && (
                    <span
                      onClick={() =>
                        handleSelectedWinner({
                          winner_id: t.player_b.id,
                          winner_name: t.player_b.name,
                          match_id: t.id,
                        })
                      }
                      title={t.player_b.id}
                    >
                      <div
                        className={`${
                          t.player_b.is_online ? "bg-green-500" : "bg-red-800"
                        } + rounded-full w-3 h-3 my-auto mx-1`}
                      ></div>
                      <p className="cursor-pointer my-auto">
                        {t.player_b.name}(No.{t.player_b.participant_number})
                      </p>
                    </span>
                  )}
                </th>
                <th>
                  {t.rooms?.map((r, index) => (
                    <p className="m-0 p-0" key={index}>
                      {r.winner_name}
                    </p>
                  ))}
                  {/* <div>
                    <p title={t.winner_id} className="my-auto">
                      {t.winner_id
                        ? t.winner_id === t.player_a?.id
                          ? t.player_a.name
                          : t.player_b.name
                        : ""}
                    </p>
                  </div> */}
                </th>
                <th className="h-full">
                  {t.rooms?.map((r, index) => (
                    <div key={index}>
                      <p
                        className={
                          `${r.status === "done" ? "text-emerald-500" : ""}
                      ${r.status === "ongoing" ? "text-orange-500" : ""} ` +
                          " m-0"
                        }
                        key={index}
                      >
                        {r.status}
                      </p>
                      <p className="text-slate-400  text-xs m-0">
                        {r.status_details}
                      </p>
                    </div>
                  ))}
                </th>
                <th className="py-2 pl-4 ">
                  <button
                    onClick={() =>
                      fightClicked(
                        t.player_a,
                        t.player_b,
                        t.id,
                        index + 1,
                        t.observer?.account_id
                      )
                    }
                    disabled={
                      t.rooms?.length !== 0 ||
                      !(t.player_a?.is_online && t.player_b?.is_online)
                    }
                    className="fightBtn w-14 px-2 py-2 rounded-md text-white"
                  >
                    Fight
                  </button>
                </th>
                <th className="pl-2 py-2">
                  <button
                    onClick={() =>
                      fightClicked(
                        t.player_a,
                        t.player_b,
                        t.id,
                        index + 1,
                        t.observer?.account_id
                      )
                    }
                    disabled={
                      t.rooms?.length === 0 ||
                      t.rooms.slice(-1)[0].status === "ongoing" ||
                      !(t.player_a?.is_online && t.player_b?.is_online)
                    }
                    className="rematchBtn px-2 py-2 rounded-md text-white"
                  >
                    Rematch
                  </button>
                </th>
                <th
                  className={`${!state.isObserve ? "hidden " : ""}` + " w-24"}
                >
                  <Select
                    disabled={!t.observer?.account_id ? false : true}
                    showArrow={t.observer?.account_id ? false : true}
                    style={{ width: 120, marginLeft: 20 }}
                    onChange={(value) =>
                      handleObserverChanged(parseInt(value), index)
                    }
                    value={t.observer?.name}
                  >
                    {state.availibleList.map((o, index) => (
                      <Option key={o.account_id} value={index}>
                        {o.name}
                      </Option>
                    ))}
                  </Select>
                  <div
                    className={
                      `${t.observer ? "" : "hidden"}` +
                      " my-auto text-gray-400 text-xs ml-5 cursor-pointer"
                    }
                    onClick={() => handleObserverClearClicked(index)}
                  >
                    clear
                  </div>
                </th>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <footer></footer>
    </div>
  );
}

export default Tournament;
